.advantages{
  &__content{
    display: flex;
    justify-content: space-between;
  }
  &__box{
    margin-top: 60px;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    max-width: 430px;
    background: rgba(255,255,255,0.7);
    overflow: hidden;
    &__free {
      position: absolute;
      top: 2px;
      right: -28px;
      transform: rotate(45deg);
      background-color: #e57f23;
      width: 100px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      color: #111183;
    }
  }
  &__title{
    position: relative;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 5px;
  }
  &__icon{
    &-crew{
      margin-left: 16px;
    }
    &-portfolio{
      margin-right: 16px;
    }
  }
  &__header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 11px;
  }
  &__desc{
    font-weight: 400;
    font-size: 16px;
    color: $orange;
    text-align: center;
  }
  &__list{
    margin-bottom: 15px;
  }
  &__item{
    position: relative;
    padding-left: 16px;
    line-height: 1.33;
    padding-bottom: 6px;

    &:before{
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $blue;
      left: 0;
      top: 8px;
    }
  }
  &__btn{
    max-width: 100%;
    transition: .2s;
    margin: 0 auto;

    &:hover {
      box-shadow: 0 0 20px rgba(145, 145, 145, 0.6);
    }
  }
  &__arrow{
    display: none;
  }
}

@include adaptive(tl) {
  .advantages{
    &__content{
    }
    &__arrow{
      display: block;
      width: 320px;
      margin: 0 auto;
      position: relative;
      &-left, &-right{
        outline: none;
        position: absolute;
        top: 15px;
        margin: auto;
        width: 30px;
        height: 50px;
        z-index: 1;
        color: $orange;
        font-size: 11px;
        padding-bottom: 2px;
        display: flex;
        align-items: center;
        line-height: 1;
        &:after, &:before{
          content: '';
          position: absolute;
          width: 1px;
          height: 29px;
          background: $orange;
        }
        &:after{
          bottom: 0;
        }
        &:before{
          top: 0;
        }
      }
      &-left{
        left: 0;
        padding-left: 15px;
        transition: left 1s, top 1s;
        &:after, &:before{
          left: 10px;
        }
        &:after{
          transform: rotate(-45deg);
        }
        &:before{
          transform: rotate(45deg);
        }
        &.crew-slider__arrow--indent{
          font-size: 0;
          left: 180px;
          top: 160px;
          width: 40px;
        }
      }
      &-right{
        right: 0;
        padding-right: 15px;
        transition: right 1s, top 1s;
        &:after, &:before{
          right: 10px;
        }
        &:after{
          transform: rotate(45deg);
        }
        &:before{
          transform: rotate(-45deg);
        }
        &.crew-slider__arrow--indent{
          font-size: 0;
          right: 180px;
          top: 160px;
          width: 40px;
        }

      }
    }
    &__btn{
      width: 100%;
      max-width: 260px;
    }
  }
}

@include adaptive(m) {
  .advantages{
    //padding: 24px 0;
    &__title{
      font-size: 18px;
    }
    &__desc{
      font-size: 12px;
      margin-bottom: 0;
    }
    &__box{
      //max-width: 100%;
      //padding-left: 45px;
      &:not(:last-child){
        margin-bottom: 28px;
      }
      &--portfolio{
        margin-left: 0;
      }
      &:before{
        width: 28px;
        height: 28px;
        -webkit-background-size: 16px 16px;
        background-size: 16px 16px;
      }
    }
    &__item{
      font-size: 14px;
      &:before{
        top: 4px;
      }
      &:not(:last-child){
        margin-bottom: 8px;
      }
    }
    &__btn{
      //margin-left: -45px;
      //width: 300px;
      //max-width: 300px;
    }
    &__list{
      margin-bottom: 16px;
    }
  }
}

@include adaptive(sm) {
  .advantages{
    &__content{
      flex-direction: column;
    }
    &__title{
      font-size: 20px;
      margin-bottom: 0;
    }
    &__desc{
      text-align: center;
    }
    &__header{
      justify-content: center;
      flex-direction: row-reverse;
      margin-left: -51px;
      &--portfolio{
        flex-direction: row;
        justify-content: center;
      }
    }
    &__icon{
      &-portfolio, &-crew{
        width: 35px;
        height: 35px;
        -webkit-background-size: 20px;
        background-size: 20px;
      }
      &-crew{
        margin-left: 0;
        margin-right: 16px;
      }
    }
    &__item{
      &:not(:last-child){
        margin-bottom: 3px;
      }
    }
    &__box{
      max-width: 100%;
      margin: 0 auto;
      &:not(:last-child){
        border-bottom: 1px solid #ddd;
        padding-bottom: 20px;
        margin-bottom: 10px;
      }
    }
    &__btn{
      display: none;
    }
    &__list{
      margin-bottom: 0;
    }
    &__arrow{
      &-left{
        left: 10px;
      }
      &-right{
        right: 10px;
      }
    }
  }
}

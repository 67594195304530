.lang{
  position: relative;
  z-index: 2;
  cursor: pointer;
  &__list{
    position: absolute;
    box-shadow: 0 1px 2px rgba(38, 50, 56, 0.32);
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    &--open{
      opacity: 1;
      visibility: visible;
    }
    &-link{
      display: flex;
      align-items: center;
      height: 38px;
      padding: 5px 20px;
      background: #fff;
      &:hover{
        background: $grey-f;
        color: $orange;
      }
    }
  }
  &__current{
    text-transform: uppercase;
    position: relative;
    min-width: 88px;
    height: 38px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    transition: all .3s;
    &:after{
      content: '';
      width: 12px;
      height: 8px;
      margin-left: 20px;
      background: url("../img/icon-arrow.svg") center no-repeat;
      -webkit-background-size: 100% auto;
      background-size: 100% auto;
      transition: all .3s;
    }
    &--active{
      box-shadow: 0 1px 3px rgba(38, 50, 56, 0.32);
      &:after{
        transform: rotate(180deg);
      }
    }
  }
}

.main-partners{
  padding: 25px 0 35px;
  background: #f2f3f7;
  position: relative;
  &__container{
    position: relative;
  }
  &__block{
    width: 100%;
    background: #fff;
    margin-top: 30px;
    border-radius: 4px;
  }
  &__title{
    width: 100%;
    border-bottom: 1px solid #eee;
    text-align: center;
    font-size: 24px;
    padding: 5px;
  }
  &__footer{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #eee;
    padding: 8px;
  }
  &__info{
    display: flex;
    padding: 12px 0;
  }
  &__data{
    padding: 0 12px;
    min-width: 300px;
    max-width: 300px;
  }
  &__img{
    width: 140px;
    max-height: 150px;
    display: block;
    margin: 0 auto 12px;
  }
  &__desc{
    padding: 12px 24px;
    border-left: 1px solid #eee;
  }
  &__slogan{
    text-align: center;
    font-size: 13px;
    display: block;
    width: 100%;
  }
  &__arrows{
     //position: relative;
     .main-partners{
       &__arrow{
         &-left, &-right{
           outline: none;
           -webkit-background-size: cover;
           background-size: cover;
         }
         &-left{
           left: 0;
           background-image: url("../img/dark-slider-right.svg");
         }
         &-right{
           right: 0;
           background-image: url("../img/dark-slider-left.svg");
         }
       }
     }
   }
}

@include adaptive(t) {
  .main-partners{
    &__arrows{
      display: none !important;
    }
  }
}

@include adaptive(m) {
  .main-partners{
    &__title{
      font-size: 15px;
    }
    &__slogan{
      font-size: 12px;
    }
    &__info{
      flex-direction: column;
      padding: 12px;
    }
    &__data{
      min-width: auto;
      max-width: 100%;
    }
    &__desc{
      border-left: none;
      padding: 12px 0 0;
      font-size: 14px;
      border-top: 1px solid #eee;
      margin-top: 12px;
    }
  }
}

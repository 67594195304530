.contacts{
  &__item{
    margin-bottom: 25px;
    &-title{
      font-size: 14px;
      color: #fff;
      font-weight: 600;
      margin-bottom: 10px;
    }
    &-value{
      display: block;
      color: #fff;
      font-size: 14px;
      margin-bottom: 10px;
      &--tel{
        color: $blue;
        font-size: 16px;
        text-decoration: underline;
      }
    }
    &-icon{
      display: inline-block;
      margin-top: 14px;
      width: 32px;
      height: 32px;
      background: url("../img/icon-mesenger.svg") center no-repeat;
      &:not(:last-child){
        margin-right: 34px;
      }
      &--telegram{
        background-image: url("../img/icon-telegram.svg");
      }
      &--viber{
        background-image: url("../img/icon-viber.svg");
      }
    }
  }
}

@include adaptive(m) {
  .contacts{
    &__item-value{
      &--icons{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

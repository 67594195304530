.btn{
  border-radius: 4px;
  background: $orange;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  max-width: 280px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &--second{
    background: $blue;
  }
}

@include adaptive(m) {
  .btn{
    max-width: 100%;
  }
}

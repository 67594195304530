.why{
  margin-top: 80px;
  &__content{
    display: flex;
    justify-content: space-between;
    margin-top: 52px;
  }
  &__item{
    flex: 1;
    max-width: 330px;
    position: relative;
    &:not(:last-child){
      &:after{
        content: '';
        position: absolute;
        height: 40px;
        width: 1px;
        background: $blue;
        top: -5px;
        left: calc(100% + 20px);
        transform: rotate(27deg);
      }
    }
  }
  &__title{
    color: $black;
    font-size: 24px;
    padding-left: 52px;
    position: relative;
    margin-bottom: 20px;
    font-weight: 400;
    &:before{
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      top: 2px;
      left: 0;
      background: url("../img/icon-idea.svg") center no-repeat;
    }
    &--search{
      &:before{
        background-image: url("../img/icon-search.svg");
      }
    }
    &--auto{
      &:before{
        background-image: url("../img/icon-settings.svg");
      }
    }
  }
  &__desc{
    padding-left: 52px;
    line-height: 1.33;
  }
  &__btn{
    margin: 25px auto 0;
  }
}

@include adaptive(t) {
  .why{
    &__item{
      &:not(:last-child){
        &:after{
          display: none;
        }
      }
    }
  }
}

@include adaptive(m) {
  .why{
    padding-top: 25px;
    padding-bottom: 24px;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 300px;
      height: 1px;
      background: $grey;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &__content{
      flex-direction: column;
      margin-top: 26px;
    }
    &__item{
      &:not(:last-child){
        padding-bottom: 12px;
        border-bottom: 1px solid $blue;
        margin-bottom: 25px;
      }
      &:after{
        display: none;
      }
    }
    &__title{
      font-size: 18px;
      margin-bottom: 10px;
    }
    &__btn{
      margin-top: 35px;
    }
  }
}

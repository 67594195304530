.companies{
  background: $grey-f;
  padding-top: 25px;
  padding-bottom: 35px;
  &__desc{
    text-align: center;
    font-size: 12px;
    margin-top: 30px;
    margin-bottom: 57px;
  }
  &__slider-wrap{
    position: relative;
  }
}

@include adaptive(m) {
  .companies{
    padding-top: 48px;
    padding-bottom: 0;
    &__desc{
      display: none;
    }
  }
}

.services{
  &__content{
    display: flex;
  }
  &__timer{
    background: $blue;
    width: 43%;
    padding: 71px 36px 170px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__pack{
    width: 57%;
    padding-top: 71px;
    padding-bottom: 20px;
    border: 1px solid #f2f3f7;
    &-title{
      font-size: 32px;
      color: #000;
      padding-left: 120px;
    }
  }
  &__list{
    padding-left: 66px;
    margin-top: 50px;
    overflow: hidden;
  }
  &__item{
    display: flex;
    align-items: flex-start;
    padding: 15px 0;
    position: relative;
    &:not(:last-child){
      &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: $grey;
        bottom: 0;
        left: 54px;
      }
    }
    &-title{
      margin-left: 14px;
      max-width: 290px;
      min-width: 290px;
      margin-top: -2px;
    }
    &-icon{
      width: 40px;
      img{
        display: block;
        max-width: 100%;
        max-height: 20px;
        margin: 0 auto;
      }
    }
    &-duration, &-price{
      min-width: 100px;
      max-width: 100px;
      line-height: 1;
    }
    &-duration{
      text-align: center;
      color: #78909c;
    }
    &-price{
      text-align: right;
      font-weight: 600;
      font-size: 18px;
    }
  }
  &__sublist{
    margin-top: 5px;
    &-item{
      font-size: 11px;
      position: relative;
      padding-left: 10px;
      &:before{
        content: '';
        position: absolute;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        background: $blue;
        top: 6px;
        left: 0;
      }
    }
  }
  &__total{
    height: 80px;
    background: $grey-f;
    margin-top: 100px;
    display: flex;
    align-items: center;
    &-title{
      font-size: 18px;
    }
    &-wrap{
      padding-left: 120px;
      display: flex;
      justify-content: space-between;
      width: 610px;
    }
  }
  &__price{
    display: flex;
    &-previous{
      font-size: 24px;
      font-weight: 600;
      text-decoration: line-through;
      margin-right: 18px;
    }
    &-current{
      font-size: 24px;
      font-weight: 600;
      color: $orange;
    }
  }
  &__btn{
    margin-left: 345px;
    margin-top: 45px;
  }
  &__title{
    font-weight: 400;
    color: #fff;
    font-size: 32px;
    margin-bottom: 31px;
    max-width: 345px;
  }
  &__desc{
    color: #fff;
    font-weight: 400;
    margin-bottom: 65px;
    max-width: 345px;
  }
}

@include adaptive(t) {
  .services{
    &__btn{
      margin-left: 280px;
    }
  }
}

@include adaptive(m) {
  .services{
    &__content{
      flex-direction: column;
    }
    &__timer{
      width: 100%;
      padding: 12px 10px 24px;
      align-items: center;
    }
    &__title{
      max-width: 300px;
      font-size: 16px;
      margin-bottom: 17px;
      padding: 0 30px;
    }
    &__desc{
      display: none;
    }
    &__pack{
      width: 100%;
      border: none;
      padding-top: 26px;
      padding-bottom: 24px;
      border-bottom: 1px solid $grey;
      &-title{
        font-size: 16px;
        padding-left: 40px;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__list{
      padding-left: 25px;
      max-width: 320px;
      margin: 0 auto;
    }
    &__item{
      padding: 25px 0;
      &-icon{
        width: 30px;
        img{
          max-height: 20px;
        }
      }
      &:not(:last-child){
        &:after{
          left: 38px;
        }
      }
      &-title{
        margin-left: 8px;
        font-size: 12px;
        min-width: 120px;
        max-width: 120px;
      }
      &-duration{
        font-size: 12px;
      }
      &-duration, &-price{
        min-width: 60px;
        max-width: 60px;
      }
      &-price{
        font-size: 14px;
      }
    }
    &__btn{
      margin: 18px auto 0;
      max-width: 300px;
    }
    &__total{
      margin-top: 0;
      justify-content: center;
      height: 40px;
      &-wrap{
        padding-left: 50px;
        max-width: 290px;
        align-items: center;
      }
      &-title{
        font-size: 14px;
      }
    }
    &__price{
      align-items: center;
      &-previous{
        font-size: 18px;
      }
      &-current{
        font-size: 18px;
      }
    }
    &__sublist{
      display: none;
    }
  }
}

.crew-slider{
  padding-top: 10px;
  position: relative;
  background: #fff url("../img/main-men.png") center 50px no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
  transition: background 1s;
  &--sailor{
    -webkit-background-size: 200%;
    background-size: 200%;
    -ms-background-position-x: left;
    background-position-x: left;
    -ms-background-position-y: 50px;
    background-position-y: 50px;
  }
  &--company{
    -webkit-background-size: 201%;
    background-size: 201%;
    -ms-background-position-x: right;
    background-position-x: right;
    -ms-background-position-y: 0;
    background-position-y: 0;
  }
  &__arrow{
    &-left, &-right{
      outline: none;
      position: absolute;
      top: 170px;
      margin: auto;
      width: 80px;
      height: 50px;
      z-index: 1;
      color: $orange;
      font-size: 11px;
      padding-bottom: 2px;
      display: flex;
      align-items: center;
      line-height: 1;
      &:after, &:before{
        content: '';
        position: absolute;
        width: 1px;
        height: 29px;
        background: $orange;
      }
      &:after{
        bottom: 0;
      }
      &:before{
        top: 0;
      }
    }
    &-left{
      left: 50px;
      padding-left: 15px;
      transition: left 1s, top 1s;
      &:after, &:before{
        left: 10px;
      }
      &:after{
        transform: rotate(-45deg);
      }
      &:before{
        transform: rotate(45deg);
      }
      &.crew-slider__arrow--indent{
        font-size: 0;
        left: 180px;
        top: 160px;
        width: 40px;
      }
    }
    &-right{
      right: 50px;
      padding-right: 15px;
      transition: right 1s, top 1s;
      &:after, &:before{
        right: 10px;
      }
      &:after{
        transform: rotate(45deg);
      }
      &:before{
        transform: rotate(-45deg);
      }
      &.crew-slider__arrow--indent{
        font-size: 0;
        right: 180px;
        top: 160px;
        width: 40px;
       }

    }
    &--hide{
      visibility: hidden;
      opacity: 0;
    }
  }
  &__nav{
    position: absolute;
    bottom: 30px!important;
    text-align: center;
    z-index: 1;
    .swiper-pagination-bullet{
      background: #F2F3F7;
      height: 4px;
      width: 58px;
      border-radius: 3px;
      opacity: 1;
      margin: 0 10px!important;
      &-active{
        background: $orange;
      }
    }
  }
  &__title{
    text-align: center;
    color: #333;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    &--dark{
      font-weight: 600;
      color: #111183;
    }
    &--light{
      font-weight: 600;
      color: #e57f23;

    }
  }
  &__btn{
    margin: 23px auto 0;
    max-width: 330px;
  }
  &__slide{
    //background: #fff;
    min-height: 700px;
    //height: calc(100vh - 89px);
    max-height: 700px;
  }
}

@include adaptive(t) {
  .crew-slider{
    -ms-background-position-y: 150px;
    background-position-y: 150px;
    &__title{
      margin-bottom: 60px;
    }
    &__arrow{
      &-left{
        &.crew-slider__arrow--indent{
          left: 50px;
        }
      }
      &-right{
        &.crew-slider__arrow--indent{
          right: 50px;
        }
      }
    }
  }
}

@include adaptive(tl) {
  .crew-slider{
    &__title{
      font-size: 18px;
      margin-bottom: 40px;
      padding: 0 10px;
    }
    &__slide{
      min-height: 590px;
      max-height: max-content;
    }
    .container{
      width: 750px;
    }
    &__btn{
      max-width: 300px;
    }
    &__arrow{
      &-right, &-left{
        font-size: 0;
        width: 30px;
        top: 110px;
      }
      &-right{
        right: 10px;
        &.crew-slider__arrow--indent{
          right: 10px;
        }
      }
      &-left{
        left: 10px;
        &.crew-slider__arrow--indent{
          left: 10px;
        }
      }
    }
  }
}

@include adaptive(sm) {
  .crew-slider{
    -ms-background-position-y: 100%;
    background-position-y: 100%;
    -webkit-background-size: 800px;
    background-size: 800px;
    &--sailor{
      -webkit-background-size: 200%;
      background-size: 200%;
    }
    &--company{
      -webkit-background-size: 201%;
      background-size: 201%;
    }
    .container{
      width: 320px;
    }
    &__arrow{
      &-right{
        top: 255px;
        &.crew-slider__arrow--indent{
          right: 10px;
        }
      }
      &-left{
        top: 88px;
        &.crew-slider__arrow--indent{
          left: 10px;
        }
      }
    }
    &__title{
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
      &--min{
        font-size: 12px;
      }
    }
    &__slide{
      min-height: 615px;
      padding-bottom: 90px;
    }
    &__nav{
      display: none;
    }
    &__btn{
      //position: absolute;
      //bottom: 20px;
      //left: 0;
      //right: 0;
      //margin: auto;
    }
  }
}
@include adaptive(s) {
      .crew-slider{
        &__slide{
          min-height: 568px;
          padding-bottom: 90px;
        }
    }
}
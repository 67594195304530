.position{
  padding-top: 30px;
  padding-bottom: 30px;
  &__title{
    margin-bottom: 20px;
  }
  &__link{
    display: block;
    margin: 0 auto 60px;
    width: 300px;
    text-align: center;
    font-size: 24px;
    &--hide{
      display: none;
    }
  }
  &__btn{
    margin: 85px auto 0;
    height: 50px;
    text-align: center;
  }
  &__tabs{
    width: 100%;
    border-bottom: 1px solid $blue;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    &-item{
      cursor: pointer;
      width: 220px;
      height: 48px;
      border: 1px solid #999;
      border-bottom: 1px solid $blue;
      color: #999;
      font-size: 24px;
      margin: 0 3px -1px;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      &--active{
        border-color: $blue;
        border-bottom-color: #fff;
        color: $blue;
      }
    }
  }

  &__content{
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
  }
  &__container{
    position: relative;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  &__vacancy{
    max-width: 990px;
    margin: 0 auto;
    padding: 5px;
    &-nav{
      position: absolute;
      bottom: -30px!important;
      text-align: center;
      z-index: 1;
      left: 0;
      right: 0;
      width: 100%;
      .swiper-pagination-bullet{
        background: #F2F3F7;
        height: 4px;
        width: 58px;
        border-radius: 3px;
        opacity: 1;
        margin: 0 10px!important;
        &-active{
          background: $orange;
        }
      }
    }
  }
  &__resume{
    max-width: 990px;
    margin: 0 auto;
    padding: 5px;
    &-nav{
      position: absolute;
      bottom: -30px!important;
      text-align: center;
      z-index: 1;
      left: 0;
      right: 0;
      width: 100%;
      .swiper-pagination-bullet{
        background: #F2F3F7;
        height: 4px;
        width: 58px;
        border-radius: 3px;
        opacity: 1;
        margin: 0 10px!important;
        &-active{
          background: $orange;
        }
      }
    }
  }
  &__resume, &__vacancy{
    &-arrows{
      &-left, &-right{
        outline: none;
        -webkit-background-size: cover;
        background-size: cover;
      }
      &-left{
        //left: -40px;
        background-image: url("../img/dark-slider-right.svg");
      }
      &-right{
        //right: -40px;
        background-image: url("../img/dark-slider-left.svg");
      }
    }
    &-counter{
      background: #fff;
      position: absolute;
      z-index: 1;
      bottom: 33px;
      left: 0;
      right: 0;
      margin: auto;
      width: 55px;
      text-align: center;
      font-size: 12px;
      color: #333;
    }
  }
}

@include adaptive(t) {
  .position{
    &__resume, &__vacancy{
      &-arrows{
        display: none!important;
      }
    }
  }
}

@include adaptive(m) {
  .position{
    padding-top: 48px;
    padding-bottom: 48px;
    &__content{
      margin-bottom: 10px;
    }
    &__tabs{
      &-item{
        font-size: 16px;
        height: 32px;
        width: 125px;
        //margin: 0 10px;
      }
    }
    &__resume, &__vacancy{
      &-nav{
        .swiper-pagination-bullet{
          width: 24px;
          height: 2px;
          margin: 0 5px !important;
        }
      }
      &-arrows{
        display: none!important;
      }
      &-counter{
        display: none;
      }
    }
    &__container{
      padding-bottom: 10px;
    }
    &__btn{
      margin-top: 60px;
      max-width: 300px;
    }
  }
}

.counters-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 40px;

  &__item {
    color: #ffffff;
    border: 2px solid #ffffff;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    min-width: 200px;
    transition: .2s;

    &__img {
      width: 60px;
    }

    &__num {
      font-size: 40px;
      font-weight: 600;
    }

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 20px #263238;
    }
  }

  &__wrapper {
    margin-top: 0;
    min-height: 400px;
    background-color: #263238;
    background-image: url("../img/counters-body-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

@include adaptive(m) {
  .counters-body {
    flex-direction: column;
    row-gap: 20px;
    padding: 30px;
  }
}
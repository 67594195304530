.main-slider{
  width: 100%;
  height: 475px;
  &__content{
    position: relative;
    z-index: 1;
  }
  &__slide{
    width: 100%;
    height: 475px;
    background-repeat: no-repeat;
    background-position: center top;
    -webkit-background-size: cover;
    background-size: cover;
    background-color: $blue;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(#007FB9, .78);
      //z-index: -1;
    }
  }
  &__title{
    margin-bottom: 38px;
  }
  &__arrows{
    .main-slider__arrow-left{
      background-image: url("../img/icon-slide-right.svg");
      outline: none;
      right: 50px;
    }
    .main-slider__arrow-right{
      background-image: url("../img/icon-slide-left.svg");
      left: 50px;
      outline: none;
    }
  }
  &__nav{
    position: absolute;
    bottom: 30px!important;
    text-align: center;
    z-index: 1;
    .swiper-pagination-bullet{
      background: #F2F3F7;
      height: 4px;
      width: 58px;
      border-radius: 3px;
      opacity: 1;
      margin: 0 10px!important;
      &-active{
        background: $orange;
      }
    }
  }
}

@include adaptive(m) {
  .main-slider{
    height: 160px;
    &__slide{
      height: 160px;
      padding: 25px 15px 35px;
    }
    &__title{
      margin-bottom: 12px;
    }
    &__nav{
      bottom: 10px !important;
      .swiper-pagination-bullet{
        height: 2px;
        width: 28px;
      }
    }
    &__arrows{
      .main-slider__arrow-left, .main-slider__arrow-right{
        width: 11px;
        height: 20px;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .main-slider__arrow-left{
        right: 10px;
      }
      .main-slider__arrow-right{
        left: 10px;
      }
    }
  }
}

.menu{
  &__list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
    &-item{
      &:not(:last-child){
        margin-right: 24px;
      }
    }
    &-link{
      font-size: 16px;
      color: $black;
      font-weight: 600;
      transition: 0.2s;
      &:hover{
        color: #111183;
      }
    }
  }
  &__close{
    display: none;
  }
}

@include adaptive(m) {
  .menu{
    &__close{
      position: absolute;
      z-index: 1;
      left: 10px;
      display: block;
    }
    &__list-item{
      position: relative;
      &:not(:last-child){
        margin-right: 0;
      }
      &:before{
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-position: center;
        -webkit-background-size: cover;
        background-size: cover;
      }
      &--main{
        &:before{
          background-image: url('../img/icon-main.svg');
        }
      }
      &--resume{
        &:before{
          background-image: url('../img/icon-resume.svg');
        }
      }
      &--vacancy{
        &:before{
          background-image: url('../img/icon-vacancies.svg');
        }
      }
      &--crewing{
        &:before{
          background-image: url('../img/icon-companies.svg');
        }
      }
      &--news{
        &:before{
          background-image: url('../img/icon-news.svg');
        }
      }
      &--contacts{
        &:before{
          background-image: url('../img/icon-contacts.svg');
        }
      }
      &--services{
        &:before{
          background-image: url('../img/icon-services.svg');
        }
      }
    }
    &__list-link{
      padding-left: 15px;
      padding-right: 15px;
      display: inline-block;
    }
  }
}

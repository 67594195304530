.companies-slider{
  //display: flex;
  //justify-content: space-between;
  max-width: 980px;
  margin: 0 auto;
  &__slide{
    width: 300px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(38, 50, 56, 0.32);
    &--join{
      background-color: transparent;
      box-shadow: none;
    }
  }
  &__join{
    height: 236px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #6b6b69;
    background: rgba($black, .04) url("../img/icon-join.svg") center no-repeat;
    margin-bottom: 4px;
  }
  &__btn{
    max-width: 100%;
  }
  &__title{
    text-align: center;
    font-size: 20px;
    padding: 5px 30px;
    border-bottom: 1px solid $grey;
  }
  &__wrap-logo{
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__logo{
    display: block;
    max-width: 240px;
    max-height: 150px;
    margin: 0 auto;
  }
  &__city{
    text-align: center;
    border-top: 1px solid $grey;
    padding: 8px 0;
  }
  &__arrows{
    //position: relative;
    .companies-slider{
      &__arrow{
        &-left, &-right{
          outline: none;
          -webkit-background-size: cover;
          background-size: cover;
        }
        &-left{
          //left: -40px;
          background-image: url("../img/dark-slider-right.svg");
        }
        &-right{
          //right: -40px;
          background-image: url("../img/dark-slider-left.svg");
        }
      }
    }
  }
}

@include adaptive(t) {
  .companies-slider{
    &__arrows{
      display: none !important;
    }
  }
}

@include adaptive(m) {
  .companies-slider{
    margin-top: 24px;
    flex-wrap: wrap;
    &__slide{
      margin-bottom: 15px;
      width: 142px;
      &--join{
        &:nth-child(2n+1){
          &:last-child{
            width: 100%;
          }
        }
      }
    }
    &__btn{
      display: none;
    }
    &__join{
      height: 142px;
      font-size: 14px;
      margin-bottom: 0;
    }
    &__wrap-logo{
      height: 142px;
    }
    &__logo{
      max-width: 140px;
      max-height: 120px;
    }
    &__title{
      display: none;
    }
    &__city{
      display: none;
    }
    &__arrows{
      display: none;
    }
    &__wrapper{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

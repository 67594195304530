.logo{
  display: block;
  min-width: 100px;
  max-width: 100px;
  height: 41px;
  &__pic{
    max-width: 100%;
    display: block;
  }
}

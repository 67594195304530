.subscribe{
  &__input{
    position: relative;
    width: 100%;
  }
  &__label{
    display: block;
    color: #fff;
    font-size: 12px;
    opacity: .56;
    margin-bottom: 3px;
  }
  &__field{
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(38, 50, 56, 0.12);
    background: #fff;
    color: #263238;
    height: 40px;
    font-size: 16px;
    margin-bottom: 12px;
    padding: 0 12px;
  }
  &__form{
    width: 295px;
  }
  &__btn{
    border: none;
    max-width: 100%;
  }
  &__title, &__desc{
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
  }
  &__title{
    margin-bottom: 7px;
    margin-top: 24px;
    font-size: 18px;
  }
  &__desc{
    font-size: 12px;
    margin-bottom: 12px;
  }
  &__logo, &__logo-desc{
    display: none;
  }
}

@include adaptive(m) {
  .subscribe{
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(#78909c, .4);
    margin-bottom: 24px;
    &__logo{
      display: block;
      margin: 0 auto 3px;
      max-width: 100%;
      &-desc{
        display: block;
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin-bottom: 24px;
      }
    }
  }
}

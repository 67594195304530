.vacancy-box{
  border-radius: 4px;
  width: 300px;
  //margin: 0 0 30px;
  //margin-bottom: 30px;
  box-shadow: 0 1px 3px rgba(38, 50, 56, 0.32);
  padding-bottom: 8px;
  background: #fff;
  z-index: 420;
  display: block;
  &--indent{
    margin-bottom: 50px;
    position: relative;
  }
  &--join{
    background: #EBECEF url("../img/icon-join.svg") center no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 174px;
  }
  &--resume{
    height: 153px;
  }
  &__text{
    font-size: 18px;
    color: #6B6B69;
  }
  &__group{
    width: 70px;
    position: absolute;
    bottom: -33px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    color: #c0c0c0;
    &:after, &:before{
      content: '';
      position: absolute;
      width: 30px;
      height: 1px;
      background: #c0c0c0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:after{
      right: -30px;
    }
    &:before{
      left: -30px;
    }
  }
  &__header{
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 8px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #eee;
    &--vip{
      background: #ffba00;
    }
    &--top{
      background: rgba(#007fb9, .25);
    }
  }
  &__rank{
    font-size: 18px;
    color: #333;
  }
  &__status, &__id{
    width: 15%;
    max-width: 40px;
  }
  &__status{
    background: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 18px;
  }
  &__id{
    text-align: right;
    font-size: 10px;
  }
  &__info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 9px;
    &--full{
      padding-left: 0;
      padding-right: 0;
    }
    &-item{
      display: flex;
      align-items: flex-start;
      width: 100%;
      max-width: 48%;
      margin-bottom: 5px;
      &--date{
        min-width: 135px;
      }
      &--right{
        justify-content: flex-end;
      }
      &--full{
        max-width: 100%;
      }
    }
    &-value, &-title{
      font-size: 12px;
      color: #000;
    }
    &-value{
      font-weight: 700;
      &--indent{
        margin-right: 5px;
      }
    }
    &-title{
      padding-right: 3px;
    }
    &-row{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid #d8d8d8;
      padding: 12px 12px 8px;
      &--oneline{
        flex-wrap: nowrap;
      }
    }
    &-block{
      text-transform: uppercase;
      font-size: 18px;
      color: #263238;
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
    }
  }
  &__company{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img{
      display: block;
      margin: 0 auto;
      max-height: 100px;
    }
    &-link{
      font-size: 18px;
      text-decoration: underline;
      line-height: 1.33;
      color: #2196f3;
      margin-bottom: 15px;
    }
    &-info{
      width: 100%;
      padding: 0 12px;
    }
    &-title{
      font-size: 18px;
      font-weight: 700;
      margin-top: 12px;
      margin-bottom: 7px;
    }
  }
  &__footer{
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
  }
  &__icon{
    width: 32px;
    height: 33px;
    &.bell{
      background: url("../img/icon-box-alarm-na.svg") center no-repeat;
      -webkit-background-size: 20px 21px;
      background-size: 20px 21px;
      &.on{
        background-image: url("../img/icon-box-alarm-full.svg");
      }
    }
    &.favorite{
      background: url("../img/icon-favorite-na.svg") center no-repeat;
      &.on{
        background-image: url("../img/icon-favorite.svg");
      }
    }
  }
  &__btn{
    width: 125px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #b5b6b9;
    border-radius: 4px;
    padding: 5px;
    &.in{
      border-color: #e77f07;
      .vacancy-box__btn-text{
        color: #e77f07;
      }
    }
    &.in-out{
      border-color: #81bc16;
      .vacancy-box__btn-text{
        color: #81bc16;
      }
    }
    &.out{
      border-color: #81bc16;
      .vacancy-box__btn-text{
        color: #81bc16;
      }
    }
    &-text{
      font-size: 12px;
      color: #b5b6b9;
      width: 55px;
    }
    &-icon{
      display: block;
      width: 26px;
      height: 20px;
      &.responses{
        background: url("../img/icon-apply.svg") center no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        &.in{
          background-image: url("../img/icon-applied.svg");
        }
        &.in-out{
          background-image: url("../img/icon-approved.svg");
        }
        &.out{
          background-image: url("../img/icon-out.svg");
        }
      }
    }
  }
  &__small{
    background: #fff;
  }
}

@include adaptive(m) {
  .vacancy-box{
    margin-bottom: 0;
  }
}

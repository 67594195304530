.stars{
  width: 103px;
  height: 17px;
  background: url("../img/icons-review-empty.svg") left center no-repeat;
  margin: 0 auto;
  &__inner{
    background: url("../img/icons-review-fill.svg") left center no-repeat;
    height: 17px;
  }
}

$font-stack: "Open Sans", -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif;

@import "~normalize.css/normalize.css";
@import "~swiper/dist/css/swiper.css";

@import "basic/variables";
@import "basic/mixins";
@import "basic/grid";

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.eot?iefix') format('eot'),
  url('../fonts/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold.eot?iefix') format('eot'),
  url('../fonts/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-SemiBold.eot');
  src: url('../fonts/OpenSans-SemiBold.eot?iefix') format('eot'),
  url('../fonts/OpenSans-SemiBold.woff') format('woff'),
  url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Light.eot');
  src: url('../fonts/OpenSans-Light.eot?iefix') format('eot'),
  url('../fonts/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.link{
  color: $blue;
  text-decoration: underline;
}

h1, h2, h3, h4, p {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $black;
  outline: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.375;
  font-family: $font-stack;
  font-weight: 400;
  color: $black;
  background-color: #fff;
  overflow-x: hidden;
}

section{
  margin-top:25px;
  margin-bottom:25px;
}

.main-title{
  font-size: 32px;
  color: #fff;
  text-align: center;
  line-height: 1.33;
  font-weight: 400;
}


.sect-title{
  text-align: center;
  font-weight: 400;
  color: #333;
  line-height: 1.33;
  font-size: 32px;
}

.go-to-profile {
  text-align: center;
  padding-top: 50px;
  .button {
    padding: 0 20px;
    text-transform: uppercase;
    line-height: 37px;
    display: inline-block;
    text-decoration: none;
  }
}

.button {
  background-color: #e67e22;
  height: 37px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  &:hover {
    background-color: #eb8d39;
  }
}

div.GVS_form_wrapper{
  display:block;
  max-width:500px;
  height:auto;
  background-color:#fff;
  margin-left:auto;
  margin-right:auto;
  border-radius:5px;
  -webkit-box-shadow: 0px 3px 8px 3px rgba(0,0,0,0.41);
  -moz-box-shadow: 0px 3px 8px 3px rgba(0,0,0,0.41);
  box-shadow: 0px 3px 8px 3px rgba(0,0,0,0.41);
  &-header{
    display:block;
    width:100%;
    height: 300px;
    background: #fff url('../img/GVS_form_header_bg2.png') center no-repeat;
    background-size:contain;
  }
  .GVS_form_wrapper-title{
    font-size:20px;
    color:#717171;
    font-weight:400;
  }
  span.step_1_correct{
    display:none;
    width:35px;
    height:35px;
    background: #fff url('../img/correct.png') center no-repeat;
    background-position: center;
    background-size: contain;
    float: left;
    margin-right: 5px;    
  }
  h1 {
    font-size: 22px;
    font-weight: 700;
    word-break: break-word;
    padding: 16px 0 6px;
    margin: 0;
    width: 390px;
    margin-left: auto;
    margin-right: auto;
    color:#000;
  }
}
.select_input{
  width:100%;
  -webkit-appearance: none;
  box-shadow: none;
  padding: 10px 15px;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 20px;
  font-size: 16px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
  transition: .2s ease-in-out;
  color: #5a5a5a;
  margin-bottom: 10px;
}

.rank_select_button{
  visibility: hidden;
  display:none;
  height: 60px;
  max-width: 60%;
  text-align:center;
  line-height:60px;
  width:auto;
  background: linear-gradient(180deg,#41a5fc 0,#0586ff);
  border-radius: 5px;
  box-shadow: none;
  border:none;
  color: #fff;
  cursor:pointer;
  margin-left:auto;
  margin-right:auto;
}
.rank_re_select_button{
  height: 25px;
  max-width: 200px;
  text-align:center;
  line-height:25px;
  width:auto;
  padding:3px;
  margin-left:20px;
  background: linear-gradient(180deg,#e0e0e0 0,#a2a2a2);
  border-radius: 5px;
  box-shadow: none;
  border:none;
  color: #000;
  font-size: 16px;
  font-weight:400;
  cursor:pointer; 
}
.loading{
  display:block;
  width:200px;
  height:45px;
  margin-left:auto;
  margin-right: auto;
  
}
#form_user_data {
  max-width:390px;
  margin-left:auto;
  margin-right:auto;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
}
div.leeloo-lgt-form-wrapper{
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.leeloo-lgt-form{
  box-shadow: none !important;
}
.in-frame .adaptive-container{
  background-color:#fff !important;
}
#lgt{
  display:block;
  width:500px;
  height:300px;
  position:absolute;
  background-color:#fff;
  opacity: 0.8;
}

@include adaptive(m) {
  .main-title{
    font-size: 14px;
  }
  .sect-title{
    font-size: 22px;
    font-weight:600;
  }
  .GVS_form_wrapper-title{
    font-size:18px;
    color:#717171;
    font-weight:400;
    max-width: 90%;
    margin-left:2.5%;
  }
  #lgt{
    display:block;
    width:100%;
    height:350px;
    position:absolute;
    background-color:#fff;
    opacity: 0.8;
  }
  div.GVS_form_wrapper h1 {
    max-width: 90%;
    margin-left:2.5%;
  }
  .select_input{
    max-width: 90%;
    margin-left:2.5%;
  }
  .lgt-title{
    font-size: 20px;
  }
}

@import "components/logo";
@import "components/vacancy";
@import "components/btn";
@import "components/lang";
@import "components/burger";
@import "components/menu";
@import "components/step";
@import "components/reg";
@import "components/widgets";
@import "components/timer";
@import "components/circle-icon";
@import "components/main-slider";
@import "components/crew-slider";
@import "components/show-video";
@import "components/stars";
@import "components/contacts";
@import "components/subscribe";
@import "components/counters";
@import "components/companies-slider";
@import "components/reviews-slider";
@import "components/video-modal";
@import "components/swiper-fix";
@import "sections/header";
@import "sections/advantages";
@import "sections/steps";
@import "sections/why";
@import "sections/congrats";
@import "sections/functions";
@import "sections/counters-body";
@import "sections/registration";
@import "sections/main_view";
@import "sections/services";
@import "sections/position";
@import "sections/companies";
@import "sections/main-partners";
@import "sections/reviews";
@import "sections/footer";


.header{
  height: 100vh;
  background-color: #fff;
  background-image: url("../img/main-ship.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &__line{
    padding: 22px 0;
    &-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__logo{
    margin-right: 24px;
  }
  &__btns, &__burger-wrap{
    display: none;
  }

  &__title {
    color: #333;
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 30px;
    padding-top: 10px;

    &__light {
      font-weight: 600;
      color: #e57f23;
    }

    &__dark {
      font-weight: 600;
      color: #111183;
    }
  }

  &__wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.user-info{
  width: 20px;
  height: 21px;
  background: url('../img/icon-user.svg') center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin-left: 14px;
  &--signin{
    background-image: url('../img/icon-exit.svg');
  }
  &--open{
    background-image: url('../img/icon-close.svg');
  }
}

.user-notice{
  width: 47px;
  height: 47px;
  background: #fff url('../img/icon-alarm.svg') center no-repeat;
  -webkit-background-size: 20px 21px;
  background-size: 20px 21px;
  transition: background-color .3s;
  position: relative;
  &.open{
    background-color: $grey-f;
  }
  &__count{
    font-size: 8px;
    width: 16px;
    height: 16px;
    background: $orange;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    line-height: 16px;
    text-align: center;
    right: 6px;
    top: 7px;
  }
}

@include adaptive(t) {
  .header{
    &__widgets{
      display: none;
    }
  }
}

@include adaptive(m) {
  .header{
    &__line{
      padding: 0;
      &-container{
        width: 100%;
      }
    }
    &__logo{
      max-width: 58px;
      min-width: 58px;
      height: 24px;
      margin-right: 0;
    }
    &__burger-wrap{
      width: 100px;
      display: flex;
      justify-content: flex-start;
    }
    &__btns{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100px;
    }
    &__list{
      display: flex;
      flex-direction: column;
      background: #fff;
      width: 240px;
      padding: 72px 10px 20px 35px;
      justify-content: flex-start;
      box-shadow: 0 1px 3px rgba(38, 50, 56, 0.32);
      transform: translateX(-240px);
      transition: transform .3s;
    }
    &__menu{
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#000, .4);
      display: flex;
      justify-content: flex-start;
      z-index: 1000;
      transition: opacity .3s;
      &--open{
        opacity: 1;
        visibility: visible;
        .menu__list{
          transform: translateX(0);
        }
      }
      &__close{
        position: absolute;
        z-index: 1;
        left: 20px;
        display: block;
      }
      li{
        text-align: left;
        &.menu__list-item{
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          width: 100%;
          &.mainmenu__item--main{
            display: flex;
          }
        }
      }
    }
    &__title {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 10px;
      padding-top: 10px;
      padding-bottom: 20px;
    }
    &__widgets{
      display: none;
    }
    &__lang{
      display: none;
    }
    &__reg{
      display: none;
    }
  }
}

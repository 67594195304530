.burger{
  width: 17px;
  height: 47px;
  position: relative;
  cursor: pointer;
  &__line{
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #000;
    &:before, &:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      left: 0;
      right: 0;
      background: #000;
    }
    &:before{
      top: -6px;
    }
    &:after{
      bottom: -6px;
    }
    transition: background .3s;
    &:before, &:after{
      transition: all .3s;
    }
  }
  &--open{
    .burger__line{
      background: transparent!important;
      &:before{
        top: 0;
        -ms-transform: rotate(45deg) translate(0, 0);
        -webkit-transform: rotate(45deg) translate(0, 0);
        transform: rotate(45deg) translate(0, 0);
      }
      &:after{
        bottom: -8px;
        -ms-transform: rotate(-45deg) translate(6px, -6px);
        -webkit-transform: rotate(-45deg) translate(6px, -6px);
        transform: rotate(-45deg) translate(6px, -6px);
      }
    }
  }
}

@include adaptive(m) {

}

.steps{
  margin-top: 80px;
  &__content{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }
}

@include adaptive(tl) {
  .steps{
    &__content{
      flex-direction: column-reverse;
    }
  }
}

@include adaptive(sm) {
  .steps{
    &__content{
      &--right{
        align-items: flex-end;
      }
    }
  }
}

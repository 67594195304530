.widgets{
  &__list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    &-item{
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
  &__icon{
    width: 20px;
    height: 20px;
    margin: 0 8px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    &--order{
      background-image: url("../img/widgets-icons/icon-order.svg");
    }
    &--feedback{
      background-image: url("../img/widgets-icons/icon-feedback.svg");
    }
    &--agent{
      background-image: url("../img/widgets-icons/icon-agent.svg");
    }
    &--reminder{
      background-image: url("../img/widgets-icons/icon-reminder.svg");
    }
    &--favorite{
      background-image: url("../img/widgets-icons/icon-favorite.svg");
    }
  }
  &__text-box{
    position: absolute;
    background: #fff;
    z-index: 2;
    width: 315px;
    padding: 12px 16px;
    box-shadow: 1px 1.7px 6px rgba(0,1,1,.34);
    font-size: 14px;
    top: calc(100% + 20px);
    display: none;
    &--open{
      display: block;
    }
    &:before{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 10px solid #fff;
      top: -10px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
    }
    &:after{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 12px solid rgba(#000, .1);
      top: -12px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
    }
  }
  &__text{
    color: #999;
    &:not(:last-child){
      margin-bottom: 10px;
    }
    &-close{
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      right: 3px;
      z-index: 1;
      &:after, &:before{
        content: '';
        position: absolute;
        width: 12px;
        height: 1px;
        background: #999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      &:after{
        transform: rotate(45deg);
      }
      &:before{
        transform: rotate(-45deg);
      }
    }
  }
}

.main-view {
  margin-top: 6%;

  &__items {
    background-color: rgba(256, 256, 256, 0.8);
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(38,50,56,.32);
    padding: 30px;
    width: 70%;
    padding-bottom: 60px;

    .blocks {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 40px;
      column-gap: 20px;
      &__item {
        display: flex;
        align-items: center;
        column-gap: 10px;

        &-icon {

        }

        &-description {
          text-transform: uppercase;
        }
      }
    }
  }
}

@include adaptive(m) {
  .main-view {
    &__items {
      width: 100%;

      .blocks {
        grid-template-columns: 1fr;
      }
    }
  }
}
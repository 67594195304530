.step{
    width: 100%;
    max-width: 370px;
    padding: 15px 6px 15px 40px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(38,50,56,.32);
    border-radius: 4px;
    position: relative;
    margin-right: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  &:first-child{
    margin-right: 0;
    .step__arrow-next{
      display: none;
    }
    &:after{
      display: none;
    }
  }
  &:last-child{
    .step__arrow-back{
      display: none;
    }
    &:before{
      display: none;
    }
  }
  &:after{
    content: '';
    position: absolute;
    width: 46px;
    height: 76px;
    background: #fff;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    z-index: 1;
  }
  &:before{
    content: '';
    position: absolute;
    width: 46px;
    height: 80px;
    background: #fff;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -47px;
    z-index: 1;
  }
  &__arrow-back{
    position: absolute;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    transform: rotate(39deg) skewY(12deg);
    top: 0;
    bottom: 0;
    margin: auto;
    left: -28px;
    box-shadow: inset 0 1px 2px rgba(38, 50, 56, 0.32);
    //border: 1px solid;
  }
  &__arrow-next{
    position: absolute;
    width: 49px;
    height: 49.5px;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(38, 50, 56, 0.32);
    //border: 1px solid;
    transform: rotate(39deg) skewY(12deg);
    background: #fff;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -23px;
  }
  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    max-width: 90%;
    &-wrap{
      margin-left: 0px;
    }
  }
  &__title{
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
  }
  &__desc{
    font-weight: 600;
    color: #000;
    
    display: block;
    z-index: 2;
    position: relative;
  }
  &__list{
    padding-left: 20px;
    position: relative;
    z-index: 11;
    background: #fff;
  }
  &__more-mob{
    display: none;
  }
  &__item{
    position: relative;
    padding-left: 9px;
    color: rgba(#000, .5);
    font-size: 14px;
    &:not(:last-child){
      margin-bottom: 15px;
    }
    &:before{
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $blue;
      left: 0;
      top: 8px;
    }
  }
}

@include adaptive(t) {
  .step{
    padding-left: 23px;
    padding-right: 10px;
    &__list{
      padding-left: 10px;
    }
    &__arrow{
      &-next{
        width: 40px;
        height: 40px;
        right: -18px;
      }
      &-back{
        width: 41px;
        height: 41px;
        left: -23px;
      }
    }
  }
}

@include adaptive(tl) {
  .step{
    padding: 12px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    &:not(:first-child){
      margin-bottom: 12px;
    }
    &:first-child{
      margin-right: auto;
    }
    &__header{
      margin-bottom: 0;
    }
    &__icon.circle-icon{
      width: 40px;
      height: 40px;
    }
    &__title{
      font-size: 14px;
    }
    &__desc{
      margin-top: 5px;
      font-size: 14px;
    }
    &:before, &:after{
      display: none;
    }
    &__list{
      display: none;
      &--more{
        display: block;
        width: 100%;
        background: #fff;
        padding: 12px 12px 12px 25px;
      }
    }
    &__arrow{
      &-next, &-back{
        display: none;
      }
    }
    &__more-mob{
      display: block;
      position: absolute;
      top: 6px;
      right: 10px;
      width: 21px;
      height: 30px;
      &:after, &:before{
        content: '';
        position: absolute;
        width: 12px;
        height: 1px;
        background-color: #333;
        border-radius: 1px;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: transform .3s;
      }
      &:after{
        transform: rotate(35deg);
        left: 0;
      }
      &:before{
        transform: rotate(-35deg);
        right: 0;
      }
      &--open{
        &:after{
          transform: rotate(-35deg);
        }
        &:before{
          transform: rotate(35deg);
        }
      }
    }
  }
}

@include adaptive(sm) {
  .step{
    margin-left: 0;
    margin-right: 0;
    &:first-child{
      margin-right: 0;
    }
    &__list{
      &--more{
        //top: 50%;
        //max-height: 130px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__item{
      font-size: 12px;
    }
  }
}

.registration {
  margin-top: 60px;
  margin-bottom: 60px;

  &__header {
    margin: 30px 0;
    text-align: center;

    &-desc {
      margin-top: 20px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
  }

  &__img {
    width: 40%;
  }

  &__form {
    padding: 20px;
    border-radius: 10px;
    background-color: #f2f2f2;
    box-shadow: 0 0 20px #f2f2f2;
    width: 60%;

    &__input{
      position: relative;
      width: 100%;
      margin-bottom: 12px;

      &-label{
        display: block;
        color: #263238;
        font-size: 16px;
        margin-bottom: 6px;
      }
      &-field{
        width: 100%;
        border-radius: 4px;
        border: 1px solid rgba(38, 50, 56, 0.12);
        background: #fff;
        color: #263238;
        height: 40px;
        font-size: 16px;
        padding: 0 12px;

        &.invalid {
          border-color: #c50a0a;
        }
      }

      #confirm-password_validation {
        display: none;
        color: #c50a0a;
      }
    }
    &-btn {
      margin: 20px 0;
      border: none;
      width: 100%;
      max-width: none;
      cursor: pointer;
    }
  }
}

@include adaptive(m) {
  .registration {
    position: relative;
    &__img {
      display: none;
    }

    &__form {
      width: 100%;
    }
  }

}

.hidden {
  display: none;
}

/* Example spinner animation */
#loadingAnimation {
  border: 5px solid #fff;
  border-top: 5px solid #2929d0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}

#loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 2; /* make sure it's above other content */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

$desk-width: 1700px;
$laptop-width: 1460px;
$tablet-width: 1200px;
$mobile-width: 1000px;
$mobile-middle: 768px;

@mixin adaptive($size) {
  @if $size == laptop {
    @media (max-width: $laptop-width) {
      @content
    }
  } @else if $size == tl {
    @media only screen and (max-width: $tablet-width) {
      @content
    }
  } @else if $size == t {
    @media only screen and (max-width: $laptop-width) {
      @content
    }
  } @else if $size == sm {
    @media only screen and (max-width: $mobile-middle) {
      @content
    }
  } @else if $size == m {
    @media only screen and (max-width: $mobile-width) {
      @content
    }
  }
}

.container {
  width: 1280px;
  margin: 0 auto;
  padding: {
    left: 50px;
    right: 50px;
    top: 0;
    bottom: 0;
  }
  //border: 1px solid red;
  &-fluid {
    width: 100%;
    padding: {
      left: 52px;
      right: 52px;
    }
  }
}

//@include adaptive(laptop) {
//  .container {
//    width: 1140px;
//  }
//}

//@include adaptive(tablet) {
//  .container {
//    width: 640px;
//  }
//}

@include adaptive(t) {
  .container {
    width: 1000px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@include adaptive(m) {
  .container {
    width: 320px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.circle-icon{
    display: block;
    margin-right:15px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    background: #fff url(../img/icon-anchor.svg) 50% no-repeat;
    box-shadow: 0 1px 3px rgba(38,50,56,.32);
  &--portfolio{
    background-image: url("../img/icon-portfolio.svg");
  }
  &--user{
    background-image: url(../img/icon-user-blue.svg);
  }
  &--messenger{
    background-image: url("../img/message-on-phone.svg");
    background-size:32px;
    background-position:center;  
  }
  &--resume{
    background-image: url("../img/icon-resume-blue.svg");
  }
  &--search{
    background-image: url(../img/icon-search.svg);
  }
  &--sync{
    background-size: 36px;
    background-image: url(../img/mail.svg);
    background-position: center;
  }
}

@include adaptive(sm) {
  .circle-icon{
    &--search{
      -webkit-background-size: 25px 25px;
      background-size: 25px 25px;
    }
  }
}

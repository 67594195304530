.timer{
  width: 345px;
  &__title{
    color: #fff;
    font-size: 16px;
    margin-bottom: 25px;
  }
  &__count{
    width: 80px;
    height: 66px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(38, 50, 56, 0.32);
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
  }
  &__content{
    display: flex;
    justify-content: space-between;
    width: 290px;
  }
  &__text{
    font-size: 12px;
    font-weight: 400;
    opacity: .5;
  }
  &__number{
    font-size: 32px;
    line-height: 1;
    margin-bottom: 5px;
  }
}

@include adaptive(m) {
  .timer{
    max-width: 240px;
    &__title{
      font-size: 12px;
    }
    &__content{
      width: 240px;
    }
    &__count{
      width: 64px;
      height: 52px;
    }
    &__number{
      font-size: 18px;
    }
  }
}

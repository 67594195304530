.counters{
  display: flex;
  justify-content: space-between;
  width: 275px;
  margin-right: 0;
  margin-left: auto;
  &__item{
    border: 1px solid rgba(#78909c, .4);
    border-radius: 4px;
    flex: 1;
    padding: 9px;
    transition: border .3s;
    &:not(:last-child){
      margin-right: 3px;
    }
    &:hover{
      border-color: $blue;
      .counters__desc{
        color: $blue;
      }
    }
  }
  &__num{
    border: none;
    color: $orange;
    font-size: 22px;
    font-weight: 600;
    display: block;
    text-align: left;
    width: 100%;
    height: auto;
    margin-right: 0;
    line-height: 1;
  }
  &__desc{
    font-size: 12px;
    color: #fff;
    transition: color .3s;
  }
}

@include adaptive(m) {
  .counters{
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(#78909c, .4);
  }
}

.congrats {
  margin-top: 6%;
  background-color: rgba(256, 256, 256, 0.8);
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(38,50,56,.32);
  padding: 30px;
  width: 70%;

  &__title {
    color: #333;
    margin-bottom: 30px;
    text-align: center;

    &-sub {
      font-size: 20px;
      font-weight: 400;
    }

    &-orange {
      font-weight: 600;
      color: #e57f23;
    }

    &-blue {
      font-weight: 600;
      color: #111183;
    }
  }

  &__description {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }

  &__item {
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding-bottom: 20px;
  }
}

@include adaptive(m) {
  .congrats {
    margin-top: 4%;
    padding: 10px;
    &__header {
      height: 100%;
      padding-bottom: 10px;
    }
  }
}
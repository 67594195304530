.footer{
  background-color: #263238;
  background-image: url("../img/footer-sea.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 50px;
  padding-bottom: 25px;
  &__content{
    display: flex;
    justify-content: space-between;
    max-width: 980px;
    margin: 0 auto;
    border-bottom: 1px solid #3a4950;
    padding-bottom: 50px;
  }
  &__menu-list{
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    .menu__list-link{
      color: #fff;
      font-size: 18px;
      &:hover{
        color: $orange;
      }
    }
    .menu__list-item{
      margin-bottom: 12px;
    }
  }
  &__copy{
    max-width: 980px;
    margin: 0 auto;
    padding-top: 25px;
    &-text{
      color: #fff;
      font-size: 12px;
    }
  }
}

@include adaptive(m) {
  .footer{
    padding-top: 27px;
    padding-bottom: 24px;
    &__menu{
      display: none;
    }
    &__content{
      flex-direction: column-reverse;
      padding-bottom: 0;
    }
  }
}

.reviews-slider{
  display: flex;
  max-width: 980px;
  margin: 0 auto;
  justify-content: space-between;
  &__slide{
    background: #fff;
    width: 300px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(38, 50, 56, 0.32);
    &-top{
      padding: 24px 24px 34px;
      min-height: 130px;
    }
    &-bottom{
      background: $blue;
      position: relative;
      padding: 34px 24px 24px;
    }
    &-text{
      font-size: 12px;
      padding-left: 45px;
      position: relative;
      max-height: 98px;
      min-height: 98px;
      overflow: hidden;
      &:before{
        content: '';
        position: absolute;
        width: 23px;
        height: 20px;
        top: 0;
        left: 0;
        background: url("../img/icon-comma.svg") center no-repeat;
      }
    }
    &-img{
      display: block;
      height: 52px;
      width: 52px;
      border-radius: 50%;
      border: 4px solid $blue;
      margin: auto;
      position: absolute;
      top: -26px;
      left: 0;
      right: 0;
      object-fit: cover;
    }
    &-name, &-post{
      display: block;
      width: 100%;
      text-align: center;
      color: #fff;
    }
    &-name{
      font-weight: 600;
    }
    &-post{
      font-size: 10px;
    }
  }
  &__stars{
    margin-bottom: 15px;
  }
  &__arrows{
    .reviews-slider{
      &__arrow{
        &-left, &-right{
          outline: none;
          -webkit-background-size: cover;
          background-size: cover;
        }
        &-left{
          //left: -40px;
          background-image: url("../img/dark-slider-right.svg");
        }
        &-right{
          //right: -40px;
          background-image: url("../img/dark-slider-left.svg");
        }
      }
    }
  }
  &__nav{
    text-align: center;
    display: none;
  }
}

@include adaptive(t) {
  .reviews-slider{
    &__arrows{
      display: none!important;
    }
  }
}

@include adaptive(m) {
  .reviews-slider{
    flex-direction: column;
    max-width: 225px;
    &__slide{
      margin-bottom: 10px;
      &-top{
        padding-left: 12px;
        padding-right: 12px;
      }
      &-text{
        padding-left: 32px;
        &:before{
          width: 20px;
          height: 18px;
          -webkit-background-size: cover;
          background-size: cover;
        }
      }
    }
    &__arrows{
      .reviews-slider{
        &__arrow{
          &-left, &-right{
            width: 11px;
            height: 20px;
          }
        }
      }
    }
    &__nav{
      display: block;
      .swiper-pagination-bullet{
        width: 32px;
        height: 3px;
        border-radius: 3px;
        background: #D8D8D8;
        margin: 0 7px;
        outline: none;
        &-active{
          background: $orange;
        }
      }
    }
  }
}

.video-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, .32);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  &--open{
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }
  &__content{
    max-width: 100%;
    max-height: 100%;
    width: 700px;
    height: 500px;
    padding: 20px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__close{
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    cursor: pointer;
    &:after, &:before{
      content: '';
      position: absolute;
      width: 30px;
      height: 3px;
      border-radius: 3px;
      background: #fff;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &:after{
      transform: rotate(45deg);
    }
    &:before{
      transform: rotate(-45deg);
    }
  }
}

iframe{
  max-width: 100%;
  max-height: 100%;
}


@include adaptive(m) {

}

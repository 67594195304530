
.active_sn_item{
  div, h4{
  color:#e77f07;
  }
}
.sn_item{
  cursor:pointer;
}
.smart-notifications{
  padding-top:20px;
  padding-bottom:20px;
  &-wrapper{
   padding-top:20px;
   width:1000px;
   margin: auto;
   display: flex;
   align-items: center;
   justify-content: center;

    &-left{
      width: 295px;
      height: 580px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--title{
        font-size: 20px;
        font-weight: 400;
        line-height: 1.1;
        padding-top: 5px;
        //text-transform: uppercase;
        text-align:right;
        //color:#4b4b4b;
      }
      &--description{
        font-size:14px;
        text-align:right;
        color:#747474;
        margin-bottom:15px;
      }
    }
    &-right{
      width: 295px;
      height: 580px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--title{
        font-size: 20px;
        font-weight: 400;
        line-height: 1.1;
        padding-top: 5px;
        //text-transform: uppercase;
        text-align:left;
        //color:#4b4b4b;
      }
      &--description{
        font-size:14px;
        text-align:left;
        color:#747474;
        margin-bottom:15px;
      }
    }
    &-nav{
      display: none;
      position: absolute;
      top: 100px!important;
      text-align: center;
      .swiper-pagination-bullet{
        width: 32px;
        height: 2px;
        border-radius: 3px;
        &-active{
          background: $orange;
        }
      }
    }
    &-arrows{
      display: none;
    }
  }

}
.smartphone-video{
  margin: 62px 0px 0px 65px;
  display:none;
}
.show_video{
  display:block;
}

.smart-notifications-middle{
  width: 400px;
  height:615px;
 background-image: url("../img/phone_smart2.png");
  background-repeat:no-repeat;
  background-position: center;
  background-size:contain;
}

.functions{
  margin-top: 80px;

  &__content{
    max-width: 980px;
    margin: 0 auto;
    box-shadow: 0 1px 3px rgba(38, 50, 56, 0.32);
    border-radius: 8px;
  }
  &__slider{
    padding: 5px 0;
    &-nav{
      display: none;
      position: absolute;
      bottom: 27px!important;
      text-align: center;
      .swiper-pagination-bullet{
        width: 32px;
        height: 2px;
        border-radius: 3px;
        &-active{
          background: $orange;
        }
      }
    }
    &-arrows{
      display: none;
    }
  }
  &__desc{
    text-align: center;
    font-size: 18px;
    margin-top: 22px;
    margin-bottom: 20px;
    font-weight: 400;
  }
  &__item{
    max-width:100%;
    margin: 0 auto;
    display: flex;
    padding: 32px 20px 46px;
    &:not(:last-child){
      border-bottom: 1px solid $grey;
    }
    &-icon{
      width: 40px;
      height: 40px;
      background: url("../img/icon-fn-favorite.svg") center no-repeat;
      margin-right: 20px;
      background-size: 40px;
      &--feedback{
        background-image: url("../img/icon-fn-feedback.svg");
      }
      &--reminder{
        background-image: url("../img/icon-fn-reminder.svg");
      }
      &--agent{
        background-image: url("../img/icon-fn-agent.svg");
      }
      &--queries{
        background-image: url("../img/icon-fn-queries.svg");
      }
      &--smartntifications{
        background-image: url("../img/icon-inbox.svg");
      }
    }
    &-title{
      font-size: 20px;
      font-weight: 400;
      line-height: 1.1;
      min-width: 250px;
      max-width: 250px;
      margin-right: 20px;
      padding-top: 5px;
    }
    &-desc{
      max-width: 65%;
    }
  }
}

@include adaptive(m) {
.active_sn_item{
  div, h4{
  color:#333333;
  }
}
  .functions{
    padding-top: 25px;
    padding-bottom: 0px;
    &__desc{
      display: none;
    }
    &__slider{
      &-nav{
        display: block;
        z-index: 1;
      }
      &-arrow{
        &-left, &-right{
          width: 11px;
          height: 20px;
          top:50px !important;
          -webkit-background-size: cover;
          background-size: cover;
          outline: none;
        }
        &-left{
          background-image: url("../img/dark-slider-right.svg");
        }
        &-right{
          background-image: url("../img/dark-slider-left.svg");
        }
      }
      &-arrows{
        display: block;
      }
    }
    &__content{
      margin-top: 12px;
      box-shadow: none;
      //padding-bottom: 23px;
      border-bottom: 1px solid $grey;
    }
    &__item{
      max-width: 100%;
      padding: 0 0px 44px;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0;
      &:last-child{
        border-bottom: 1px solid $grey;
      }
      &-icon{
        width: 28px;
        height: 28px;
        margin-right: 10px;
        -webkit-background-size: cover;
        background-size: cover;
      }
      &-title{
        font-size: 16px;
        text-transform:uppercase;
        min-width: auto;
        max-width: calc(100% - 40px);
      }
      &-desc{
        font-size: 14px;
        text-align:justify;
        margin-top: 8px;
        max-width: 100%;
      }
    }
  }
  
.smart-notifications{
  &-wrapper{
   max-width:100%;
   margin: auto;
   display: flex;
   flex-direction:column;
   align-items: center;
   justify-content: center;

    &-left, &-right{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      //justify-content: space-between;

      &--title{
        font-size: 16px;
        font-weight:normal;
        text-align:center;
        text-transform:uppercase;
        min-width: auto;
        max-width: 100%;
      }
      &--description{
        font-size: 14px;
        text-align:justify;
        margin-top: 8px;
        max-width: 100%;
      }
    }
    &-right{
      display:none;
    }
    &-nav{
      display: block;
      z-index: 1;
    }
    &-arrow{
      &-left, &-right{
        width: 11px;
        height: 20px;
        top:45px !important;
        -webkit-background-size: cover;
        background-size: cover;
        outline: none;
      }
      &-left{
        background-image: url("../img/dark-slider-right.svg");
      }
      &-right{
        background-image: url("../img/dark-slider-left.svg");
      }
    }
    &-arrows{
      display: block;
    }
  }  
}
}

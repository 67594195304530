.show-video{
  width: 210px;
  margin: 0 auto;
  cursor: pointer;
  &__icon{
    width: 38px;
    height: 38px;
    background: #fff url("../img/icon-triangle.svg") center no-repeat;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(38, 50, 56, 0.32);
    margin: 0 auto;
  }
  &__desc{
    font-size: 14px;
    text-align: center;
    color: #fff;
    margin-top: 16px;
    width: 100%;
  }
}

@include adaptive(m) {
  .show-video{
    &__desc{
      font-size: 10px;
      margin-top: 4px;
    }
    &__icon{
      width: 28px;
      height: 28px;
      -webkit-background-size: 7px 8px;
      background-size: 7px 8px;
    }
  }
}

.reviews{
  margin-top: 60px;
  background: $grey-f;
  padding-top: 60px;
  padding-bottom: 65px;
  margin-bottom: 0;
  &__title{
    margin-bottom: 45px;
    text-align: center;
  }
  &__btn{
    margin: 65px auto 0;
  }
  &__slider-wrap{
    position: relative;
  }
}

@include adaptive(m) {
  .reviews{
    padding-bottom: 25px;
  }
}

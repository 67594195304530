.reg{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  border-right: 1px solid $grey;
  border-left: 1px solid $grey;
  &__link{
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    padding: 8px 18px;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    margin: 0 3px;

    &.reg {
      color: white;
      background-color: #111183;
    }

    &.log {
      color: #111183;
      border: 1px solid #111183;
    }

    &:hover{
      color: white;
      box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
      background-color: rgba(104, 85, 224, 1);
    }
  }
}
